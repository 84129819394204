// Local Store Utility Functions

export const readJSONFromLocalStorage = (key: string): any | null => {
  const fetchedValue = localStorage.getItem(key);

  if (fetchedValue) {
    return JSON.parse(fetchedValue);
  }
};

export const readStringFromLocalStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};

// write JSON-stringified value to local storage based on key
export const writeJSONToLocalStorage = (key: string, value: object): void => {
  const jsonValue = JSON.stringify(value);
  localStorage.setItem(key, jsonValue);
};

// write string value to local storage based on key
export const writeStringToLocalStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

export const eraseForKey = (key: string): void => {
  localStorage.removeItem(key);
};
