import { useState, useEffect } from "react";
import { KanbanBoard } from "./types";
import { initializeBoardState } from "./utils/BoardState";
import { readJSONFromLocalStorage, writeJSONToLocalStorage } from "./utils/LocalStorage";

export const useBoardState = () => {
    const fetchLatestBoardState = (): KanbanBoard => {

        const fetchedBoardState = readJSONFromLocalStorage("boardState");

        if (fetchedBoardState) {
            return fetchedBoardState;
        }

        return initializeBoardState();
    };

    const [boardState, setBoardState] = useState(fetchLatestBoardState());

    const updateTitleForColumn = (listIndex: number, newTitle: string) => { 
        const currentBoardState = fetchLatestBoardState();
        currentBoardState.lists[listIndex].title = newTitle;
        setBoardState(currentBoardState);
    };

    const updateDescriptionForCard = (listIndex: number, cardIndex: number, newDescription: string) => {
        const newBoardState = fetchLatestBoardState();
        newBoardState.lists[listIndex].cards[cardIndex].description = newDescription;
        setBoardState(newBoardState);
    };

    useEffect(() => {
        console.log("DAKOTA " + JSON.stringify(boardState));
        writeJSONToLocalStorage("boardState", boardState);
    }, [boardState]);

    return { boardState, setBoardState, updateTitleForColumn, updateDescriptionForCard, fetchLatestBoardState };
};