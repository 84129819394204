import { Card, KanbanBoard, KanbanList } from "../types";

export const generateCards = (count: number, offset = 0): Array<Card> =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    description: `item ${k + offset}`,
  }));

// generate list

export const generateKanbanList = (title: string, cards: Array<Card>): KanbanList => {
  return {
    id: `list-${new Date().getTime()}`,
    title,
    cards,
  };
};


export const generateBoard = (): KanbanBoard => {
  const todoCards = [
    {
      id: "item-0",
      description: "Begin Your Adventure Here...",
    },
  ];

  const todoList = generateKanbanList("To Do", todoCards);
  const inFlightList = generateKanbanList("In Flight", []);
  const completedList = generateKanbanList("Completed", []);

  const kanbanBoard = {
    id: "kanban-board",
    title: "Kanban Board",
    lists: [todoList, inFlightList, completedList],
  };

  return kanbanBoard;
};