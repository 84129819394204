import React, { useState, useEffect } from 'react'
import {
    Draggable, DraggingStyle,
    NotDraggingStyle,
} from 'react-beautiful-dnd'
import InlineTextArea from './InlineTextArea';
import { useBoardState } from './useBoardState';

interface ItemProps {
    key: string;
    index: number;
    item: string;
    col: number;
    onDelete: Function;
}

export const Item = (props: ItemProps) => {
    const { updateDescriptionForCard } = useBoardState();
    const [itemValue, setItemValue] = useState(props.item);
    const [loaded, setLoaded] = useState(false);

    const { onDelete } = props;

    useEffect(() => {
        setLoaded(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        loaded && updateDescriptionForCard(props.col, props.index, itemValue);
        // eslint-disable-next-line
    }, [itemValue]);

    const getItemStyle = (
        isDragging: Boolean,
        draggableStyle: DraggingStyle | NotDraggingStyle | undefined
    ) => ({
        padding: 8 * 2,
        paddingTop: 4,
        margin: `0 4px 8px 4px`,
        background: isDragging ? "lightgreen" : "grey",
        ...draggableStyle,
    });

    return (
        <Draggable draggableId={props.item} index={props.index}>
            {(provided, snapshot) => (
                <div
                    className="rounded flex flex-col"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <button className="ml-auto text-xs" onClick={() => onDelete()}>x</button>
                    <InlineTextArea value={itemValue} setValue={setItemValue} isMultiline={true} />
                </div>)}
        </Draggable>
    )
};

export default Item