import React, { useState } from "react";

interface InlineTextAreaProps {
    value: string;
    setValue: (value: string) => void;
    isTitle?: boolean;
    isMultiline: boolean;
}

const InlineTextArea = ({ value, setValue, isTitle, isMultiline }: InlineTextAreaProps) => {
    const [editingValue, setEditingValue] = useState(value);
    const [isEditing, setIsEditing] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEditingValue(event.target.value);
    };

    const beginEditing = () => setIsEditing(true);

    const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.key === "Enter" || event.key === "Escape") {
            setValue(editingValue);
            setIsEditing(false);
        }
    };

    const onBlur = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.target.value === "") {
            setEditingValue(value);
            setIsEditing(false);
        } else {
            setValue(event.target.value);
            setIsEditing(false);
        }
    };

    return (
        <>
            {isEditing ?
                !isMultiline ? <input style={{ width: 150 }} autoFocus onFocus={e => e.currentTarget.select()} value={editingValue} onChange={handleChange} onKeyDown={onKeyDown} onBlur={onBlur} /> : <textarea className={`${isMultiline ? 'text-sm' : 'text-lg'}`} style={{ width: "100%" }} autoFocus onFocus={e => e.currentTarget.select()} value={editingValue} onChange={handleChange} onKeyDown={onKeyDown} onBlur={onBlur} />
                : <div className={`${isMultiline ? 'text-sm' : 'text-lg'}`} style={{ background: isTitle ? "none" : "grey" }} onClick={beginEditing}>{value}</div>
            }
        </>
    )
};

export default InlineTextArea