import React, { useEffect, useState } from "react";
import { writeStringToLocalStorage } from "./utils/LocalStorage";

interface MyProps {
  children: React.ReactNode;
}

export const AppWrapper = (props: React.PropsWithChildren<MyProps>) => {
  const [hits, setHits] = useState(0);
  useEffect(() => {
    async function getData() {
      console.log("Fetching data");
      fetch(
        "https://us-central1-today-f739c.cloudfunctions.net/incrementHitsByOne"
      )
        .then((response) => response.json())
        .then((data) => setHits(data.hits));
    }

    getData();
    handleLocalStorageSelfDestructSchedule();
  }, []);

  const handleLocalStorageSelfDestructSchedule = () => {
    const selfDestructDate = localStorage.getItem("selfDestructDate");
    if (selfDestructDate) {
      console.log("Date written to local storage: " + selfDestructDate);
      const now = new Date();
      const selfDestructDateObj = new Date(selfDestructDate);
      if (now > selfDestructDateObj) {
        alert("Going to clear the local storage!");
        localStorage.clear();
        writeStringToLocalStorage(
          "selfDestructDate",
          new Date(now.setDate(now.getDate() + 1)).toString()
        );
      }
    } else {
      const currentDate = new Date();

      const nextMidnight = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1,
        0,
        0,
        0
      );

      writeStringToLocalStorage("selfDestructDate", nextMidnight.toISOString());
    }
  };

  return (
    <div className="h-screen flex flex-col font-mono font-extrabold text-xl bg-white dark:bg-black">
      <div className="flex bg-white dark:bg-black w-screen h-16 flex-row items-center dark:text-white justify-between px-2">
        <div>today</div>
        <div className="flex flex-row justify-evenly items-center">
          {hits > 0 && <h1 className="px-2">{hits}</h1>}
          
          <button // Clear Local Storage
            className="px-2 font-extrabold"
            onClick={() => {
              localStorage.clear();
              alert("Local storage has been cleared");
              window.location.reload();
            }}
          >
            x
          </button>
          
         
          <button  // Delay the self-destruct
            className="px-2 font-extrabold">y</button>
          <button // Share to today Mobile - modal - maybe one more button to leave comments on a bulletin board for feedback
            className="pl-2 pr-4 font-extrabold">z</button>
  
        </div>
      </div>
      {props.children}
    </div>
  );
};
