import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import "./index.css";
import Column from "./Column";
import { generateKanbanList } from "./utils/DataUtils";
import { move, reorder } from "./utils/BoardState";
import { useBoardState } from "./useBoardState";
import { Card } from "./types";

export const Kanboard = () => {
  const { boardState, setBoardState, fetchLatestBoardState } = useBoardState();

  const [localBoardState, setLocalBoardState] = useState(boardState);

  useEffect(() => {
    setLocalBoardState(boardState);
  }, [boardState]);

  function onDragEnd(result: any) {
    const { source, destination, type } = result;

    if (!destination) {
      return;
    }

    if (type === "column") {
      const newBoardState = fetchLatestBoardState();
      const [removed] = newBoardState.lists.splice(source.index, 1);
      newBoardState.lists.splice(destination.index, 0, removed);
      setBoardState(newBoardState);
    } else {
      const sourceIndex = +source.droppableId;
      const destinationIndex = +destination.droppableId;

      if (sourceIndex === destinationIndex) {
        reorder(boardState.lists[sourceIndex].cards, sourceIndex, source.index, destination.index, boardState, setBoardState);
      } else {
        move(boardState.lists[sourceIndex].cards, boardState.lists[destinationIndex].cards, source, destination, boardState, setBoardState);
      }
    }
  }

  function addNewDummyList() {
    const newBoardState = fetchLatestBoardState();
    const newList = generateKanbanList("New List", []);
    newBoardState.lists.push(newList);
    setBoardState(newBoardState);
  }

  function deleteList(index: number) {
    const newBoardState = fetchLatestBoardState();
    newBoardState.lists.splice(index, 1);
    setBoardState(newBoardState);
  }

  function deleteItemFromColumn(columnIndex: number, itemIndex: number) {
    const newBoardState = fetchLatestBoardState();
    newBoardState.lists[columnIndex].cards.splice(itemIndex, 1);
    setBoardState(newBoardState);
  }

  const addNewItem = (index: number) => {
    const newBoardState = fetchLatestBoardState();
    const newItem: Card = {
      id: `item-${Date.now()}`,
      description: '...',
    };
    newBoardState.lists[index].cards.push(newItem);
    console.log("New Item: " + JSON.stringify(newItem));
    setBoardState(newBoardState);
  };

  return (
    <div className="flex flex-grow flex-row justify-space-evenly border-black bg-white dark:bg-black h-5/6 p-10 m-5 pr-10">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="board" direction="horizontal" type="column">
          {(provided) => (
            <div className="h-full flex flex-row overflow-x-scroll border rounded border-gray-500" ref={provided.innerRef} {...provided.droppableProps}>
              {localBoardState.lists.map((list, index) => {
                return (
                  <Column
                    deleteItemFromColumn={deleteItemFromColumn}
                    index={index}
                    key={list.id}
                    title={list.title}
                    tasks={list.cards}
                    addNewItem={addNewItem}
                    onCloseClicked={() => deleteList(index)}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <button
        className="w-20 h-10 flex justify-center items-center ml-2 mb-auto bg-white text-black rounded"
        onClick={() => {
          addNewDummyList();
        }}
      >
        Add
      </button>
    </div>
  );
};