import React, { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import InlineTextArea from './InlineTextArea';
import Item from './Item';
import { Card } from './types';
import { useBoardState } from './useBoardState';

interface ColumnProps {
  key: string;
  index: number;
  title: string;
  tasks: Array<Card>;
  onCloseClicked: Function;
  deleteItemFromColumn: Function;
  addNewItem: Function;
}

const getListStyle = (isDraggingOver: Boolean) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: 2,
  minWidth: 225,
  margin: 5,
  maxHeight: "100%",
});

export const Column = (props: ColumnProps) => {
 const { updateTitleForColumn } = useBoardState();
  const [titleValue, setTitleValue] = useState(props.title);
  const [loaded, setLoaded] = useState(false);
  const { deleteItemFromColumn } = props;

  useEffect(() => {
    setLoaded(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loaded && updateTitleForColumn(props.index, titleValue);
    // eslint-disable-next-line
  }, [titleValue]);


  return (
    <Draggable draggableId={props.index.toString()} index={props.index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div {...provided.dragHandleProps} className="column-drag-handle">
          <div className="flex flex-row justify-between px-2">
                  <InlineTextArea value={titleValue} setValue={setTitleValue} isMultiline={false} isTitle={true} />
                  <button onClick={() => { props.onCloseClicked(); }}>
                    <h1>X</h1>
                  </button>
                </div>
          </div>
          <Droppable droppableId={props.index.toString()}>
            {(provided, snapshot) => (
              <div className="overflow-y-scroll rounded" ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                
                {props.tasks.map((item, index) => (
                  <Item key={item.id} index={index} col={props.index} item={item.description} onDelete={() => deleteItemFromColumn(props.index, index)} />
                ))}
                {provided.placeholder}
                <button
                  className="w-full py-2 mt-2 text-center text-white bg-blue-500 rounded hover:bg-blue-600"
                  onClick={() => props.addNewItem(props.index)}
                >
                  +
                </button>
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};

export default Column;