import React from "react";
import "./App.css";
import { AppWrapper } from "./AppWrapper";
import { Kanboard } from "./Kanboard";

function App() {
  return (
    <AppWrapper>
      <Kanboard />
    </AppWrapper>
  );
}

export default App;
