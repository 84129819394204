// Board State Methods

import { DraggableDescriptor } from "react-beautiful-dnd";
import { Card, KanbanBoard } from "../types";
import { generateBoard } from "./DataUtils";
import { readJSONFromLocalStorage } from "./LocalStorage";

export const initializeBoardState = (): KanbanBoard => {
    const kanbanBoard: KanbanBoard = readJSONFromLocalStorage("boardState");
  
    if (kanbanBoard) {
      return kanbanBoard;
    } else {
      return generateBoard();
    }
  };

export const move = (source: Array<Card>, destination: Array<Card>, droppableSource: DraggableDescriptor, droppableDestination: DraggableDescriptor, originalState: KanbanBoard, onComplete: (boardState: KanbanBoard) => void) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const updatedBoardState = { ...originalState };
    updatedBoardState.lists[parseInt(droppableSource.droppableId)].cards =
      sourceClone;
    updatedBoardState.lists[parseInt(droppableDestination.droppableId)].cards =
      destClone;
    onComplete(updatedBoardState);
};

export const reorder = (list: Array<Card>, sourceList: number, sourceIndex: number, destinationIndex: number, originalState: KanbanBoard, onComplete: (boardState: KanbanBoard) => void) => {
    const result = Array.from(list);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);
    const newBoardState = { ...originalState };
    newBoardState.lists[sourceList].cards = result;
    
    onComplete(newBoardState);
};

export const updateItemDetails = () => {
    // Handle updating item details for a specific item
};

export const updateColumnDetails = () => {
    // Handle updating column details for a specific column
};

export const deleteItem = () => {
    // Handle deleting an item
};

// Future release - items can be duplicated in place.
export const duplicateItem = () => {
    // Handle duplicating an item
};

